import mixpanel from 'mixpanel-browser';
import Log from './monitoring';
import { getCurrentUserUuid } from "helper/user";
const dataLayerKey = 'dataLayer';

const isLocal = !process.env.NEXT_PUBLIC_DEVO_ENV;

export const initMixpanel = () => {
  mixpanel.init(process.env.MIXPANEL_TOKEN);
  Log.debug('Initialized Mixpanel', 'mixpanel', null);
};

const Mixpanel = {
  identify: (id) => {
    if (isLocal) return;
    mixpanel.identify(id);
  },
  alias: (id) => {
    if (isLocal) return;
    mixpanel.alias(id);
  },
  track: (name, props = {}) => {
    if (isLocal) return Log.info(name, 'mixpanel-local', props);;
    Log.info(name, 'mixpanel', props);
    try {
      mixpanel.track(name, props);
    } catch (error) {
      Log.error('Failed to send Mixpanel event', 'mixpanel', { name, props});
    }
  },
  people: {
    set: (props) => {
      if (isLocal) return;
      mixpanel.people.set(props);
    },
  },
};

export const logAnalytics = (key: string, item?: any) => {
  const input = item || {};
  const user_id = getCurrentUserUuid(false);

  const logExcluded = ['shop_impression'];
  if (!logExcluded.includes(key)) {
    Log.info(key, 'analytics', { ...input, user_id });
  }

  if (!process.env.NEXT_PUBLIC_DEVO_ENV) return;
  push({ ...input, user_id, event: key });
};

export const identifyToMixpanel = (userId: string = null) => {
  const mixpanel = window['mixpanel'];
  if (!mixpanel) return Log.warning(`Mixpanel client not found when identifying.`, 'mixpanel', null);
  const _userId = userId || getCurrentUserUuid(false);
  if (!_userId) return;
  mixpanel.identify(_userId);
  Log.info(`Identify Mixpanel "${_userId}".`, 'mixpanel', null);
};

var userPropertyTimeout = null;
export const setMixpanelUserProperty = (value: any, flush: boolean = true) => {
  const data = { ...value, last_session: (new Date()).toISOString() };
  const mixpanel = window['mixpanel'];
  if (!mixpanel) return Log.warning(`Mixpanel client not found when setting user properties.`, 'mixpanel', data);

  mixpanel.people.set(data);
  Log.info(`Set Mixpanel user properties`, 'mixpanel', { data, flush });

  if (flush === false) return;
  if (userPropertyTimeout) clearTimeout(userPropertyTimeout);
  userPropertyTimeout = setTimeout(() => {
    Log.info('Flush identify triggered', 'mixpanel', null);
    identifyToMixpanel();
  }, 5000); // 10 seconds
};

export const setMixpanelSuperProperty = (key: string, value: any) => {
  if (!process.env.NEXT_PUBLIC_DEVO_ENV) return;
  const mixpanel = window['mixpanel'];
  if (!mixpanel) return Log.warning(`Mixpanel client not found when setting super property "${key}".`, 'mixpanel', { key, value });

  var property = {};
  property[key] = value || '';
  mixpanel.register(property);
  Log.info(`Set Mixpanel super property "${key}" = "${value || ''}".`, 'mixpanel', null);
};

export const push = (item) => {
  try {
    dataLayer()?.push(item);
  } catch {
    Log.warning(`Error pushing ${item?.event || 'event'} to dataLayer`, 'mixpanel', item);
  }
};

export const dataLayer = () => {
  window[dataLayerKey] = window[dataLayerKey] || [];
  return window[dataLayerKey];
};

export default Mixpanel;